import React from 'react';
import './Header.css'; // assuming you have a Header.css file for styling
import logo from '../images/losMuchachos.png';

const Header = () => {
    return (
        <div className="header">
            <img src={logo} alt="logo" />  {/* replace with your image path */}
        </div>
    );
}

export default Header;
