import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import firebaseDB from './Firebase.js';
import { collection, getDocs } from 'firebase/firestore';
import 'firebase/firestore';

const containerStyle = {
  width: '800px',
  height: '600px'
};

const center = {
  lat: -34.397, 
  lng: -57.644
};

const MapUsage = () => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const querySnapshot = await getDocs(collection(firebaseDB, "geolocations"));
            const data = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
            setLocations(data);
            } catch (error) {
            console.error("Error fetching data:", error);
            }
      
    };

    fetchData();
  }, []);

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBV9b_h9cFuyTXzWDslKzYkaO0Hw3ctq14" // replace with your Google Maps API key
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        {locations.map(location => 
          <Marker key={location.id} position={{ lat: location.latitude, lng: location.longitude }} />
        )}
      </GoogleMap>
    </LoadScript>
  );
}

export default MapUsage;
