import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import MapUsage from './components/MapUsage';
import ChartUsage from './components/ChartUsage';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/usage" element={<ChartUsage />} />
        <Route path="/map" element={<MapUsage />} />
        <Route path="/:id_item" element={<App />} />
      </Routes>  
    </BrowserRouter>
  );
};

export default Router;
