import './App.css';

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from './components/Header';
import ClothingInfo from './components/ClothingInfo';
import GeolocationTracker from './components/GeolocationTracker';

// const protocol = (window.location.protocol === 'https:') ? 'https:' : 'http:';
const protocol = "https:";
const WEB_SERVICE_URL = protocol + "//lm-dapie.com:8088/stocks/";

const Test = () => {
  useEffect(() => {
    /*
    Query logic
    */
    console.log('i fire once');
  }, []);
};

const App = () => {
  const { id_item } = useParams();
  const [item, setItem] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(null);
    fetch(`${WEB_SERVICE_URL}${id_item}`)
      .then((response) => response.json())
      .then((data) => {
        setItem(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setError('Error en la conexión al servidor.\n' + error);
        setLoading(false);
      });
  }, [id_item]);

  return (
    <div className='background'>
      <Header />
      <div className='mainContainer'>
        <div className='encontraTuTalle' />
        {loading ? (
          <div className="loaderContainer">
            <div className="loader"></div>
          </div>
        ) : error ? (
          <div>Error: {error}</div>
        ) : item ? (
          <>
            <ClothingInfo item={item} />
            <GeolocationTracker item={item} />
          </>
        ) : (
          <div>No hay información para ese árticulo</div>
        )}
      </div>
      <Test />
    </div>
  );
};

export default App; 
