import React, { useEffect } from 'react';
import firebaseDB from './Firebase.js';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import 'firebase/firestore';

const GeolocationTracker = ({ item }) => {
    useEffect(() => {
      if (item == null) return;
      const trackGeolocation = async (position) => {
        const { latitude, longitude } = position.coords;

        const newData = {
          latitude,
          longitude,
          timestamp: serverTimestamp(),
          id_item: item.id,
          refproveedor: item.refproveedor
        };

        try {
          const docRef = await addDoc(collection(firebaseDB, 'geolocations'), newData);
          console.log('Document written with ID:', docRef.id, item);
        } catch (error) {
          console.error('Error adding document:', error);
        }
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(trackGeolocation);
      } else {
        console.log('Geolocation is not supported by this browser.');
      }
    // eslint-disable-next-line
    }, []);
  
    return <></>;
  };
  
export default GeolocationTracker;
